<template>
	<div class="box box_mm">
		<div class="box_main">
			<div>
				<div style="width: 3rem;height: 3rem;border: 1px solid #eee; border-radius: 10px;display: flex;align-items: center;justify-content: center;">
					<div v-if="!userqm" style="color: #ccc;font-size: 0.3rem;">请上传签名</div>
					<img v-else :src="imageUrl" alt="" style="width: 100%;height: 100%;"/>
				</div>
				<div style="width: 3rem;font-size: 0.18rem;display: flex;align-items: center;justify-content: center;margin-top: 10px;">
					<el-button type="primary" size="mini" @click="showtc=true">编辑</el-button>
				</div>
			</div>
		</div>
		
		
		<!--审核弹出-->
		<el-dialog title="编辑签名" :visible.sync="showtc" width="40%" :before-close="setsheng">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="签名图片" required>
					  		<el-upload
					  		  class="avatar-uploader"
					  		  :action="baseurl+'/api/login/upload'"
					  		  :show-file-list="false"
					  		  :on-success="handleAvatarSuccess"
					  		  :before-upload="beforeAvatarUpload">
					  		  <img v-if="imageUrl" :src="imageUrl" class="avatar">
					  		  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					  		</el-upload>
					  </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng">取 消</el-button>
				<el-button type="success" @click="queren">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchinput: '',
				showtc:false,
				imageUrl:'',
				userid:'',
				userqm:'',
				baseurl: "",
			}
		},
		created() {
			this.baseurl = this.$URL
		},
		mounted() {
			this.gettable()
		},
		methods: {
			setsheng(){
				this.showtc = false
				this.userqm = ''
			},
			queren() {
				this.$post({
					url: '/api/user/edit',
					params: {
						id:this.userid,
						qm:this.userqm
					}
				}).then((res) => {
					this.showtc = false
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			showeidt(row) {
				this.$router.push('/system/get_file/edit?id=' + row.id)
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			handleAvatarSuccess(res, file) {
				console.log(res)
				this.userqm = res.id
				this.imageUrl = res.fullurl
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 10;
			
				if (!isJPG) {
					this.$message.error('上传图片只能是 PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/user/dqdetails',
					params: {
						
					}
				}).then((res) => {
					this.userid = res.id
					this.userqm = res.qm?res.qm[0].id:''
					this.imageUrl = res.qm?res.qm[0].fullurl:''
				})
			},
			addnew() {
				this.$router.push('/system/get_file/add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'qianming_mng.scss';
</style>